.project-card img {
  height: 350px;
  width: 100%;
  object-fit: contain;
  margin: auto;
  padding: 0px 7px;
}

.admin-project-card {
  width: 525px;
  height: 955px;
  margin: 20px 15px;
  background-color: white;
  padding-top: 5px;
  padding-bottom: 5px;
  color: black;
  font-family: Georgia;
  font-size: 18px;
}

.non-admin-project-card {
  width: 525px;
  height: 655px;
  margin: 20px 15px;
  background-color: white;
  padding-top: 5px;
  padding-bottom: 5px;
  color: black;
  font-family: Georgia;
  font-size: 18px;
}

.card-title {
  font-size: 28px;
  font-weight: 700;
}

.btn {
  margin: auto;
  width: 215px;
  margin-bottom: 20px;
}

.hidden {
  display: none;
}

.project-form {
  display: flex;
  flex-flow: column wrap;
}
