.projects {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  width: 100%;
  height: 100%;
  background-color: $bgColor;
  padding-bottom: 50px;
  padding-top: 10px;
  color: $font;
  font-family: Georgia;
  font-size: 24px;
}

.project-board {
  background-color: $bgColor;
  color: $font;
  font-size: 48px;
  font-family: Georgia;
  font-weight: 500;
}

.project-board hr {
  width: 20%;
}

.add-project-form {
  font-size: 18px;
  width: 350px;
  margin: auto;
}
