@import "~@fortawesome/fontawesome-free/css/all.min.css";
@import 'semantic-ui-css/semantic.min.css';
@import 'variables';
@import 'intro';
@import 'aboutme';
@import 'navbar';
@import 'technologies';
@import 'projectCard';
@import 'projects';
@import 'connect';

body {
  background-color: $bgColor;
}
