.about-me {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  width: 100%;
  height: 100%;
  background-color: $bgColor;
  padding-top: 30px;
  padding-bottom: 150px;
  color: $font;
  font-family: Georgia;
  font-size: 24px;
}

.about-me-para {
  width: 45%;
  font-family: Georgia;
  font-size: 24px;
  padding-top: 10px;
}

.about-me img {
  border-radius: 50%;
  height: 325px;
  padding-bottom: 20px;
  padding-top: 20px;
}

.about-img {
  align-self: flex-end;
}

hr {
  background-color: #566d91;
  width: 50%;
}
